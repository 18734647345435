import React from 'react';

export const LandingHeadingSection = () => {
  return (
    <section className="my-5 pt-5 overflow-hidden">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12"> {/* has-anim fade */}
            <div className="section-title text-center mb-4">
              <p className="mb-2 text-light">
                Moses Concas x Dodici x Metabyte Studio bring you
              </p>
              <h2 className="title text-white">
                A web3 community-owned brand of street art
              </h2>
            </div>
          </div>
        </div>
        <img className="hero-logo" src="assets/images/logo.svg" />
      </div>
    </section>
  )
}