import React from 'react';
import { HeadingType, SectionHeading } from '../components/sections/SectionHeading';
import { Teams } from '../data/Teams';

interface ITeamSectionProps {
  showHeading?: boolean;
}

const defaultHeading: HeadingType = {
  title: 'Meet our legendary',
  description: 'Web3 Degens'
};

export const TeamSection: React.FC<ITeamSectionProps> = ({ showHeading }) => {
  const heading = showHeading ? defaultHeading : null;
  return (
    <section id="team" className="section bg-white">
      <div className="container">
        {heading && (
          <SectionHeading heading={heading} />
        )}
        <div className="row row-team justify-content-center">
          {Teams.map(item => (
            <div className="col-xl-2 col-lg-4 col-6">
              <div className="team-item"> {/* has-anim fade */}
                <div className="block overflow-hidden position-relative">
                  <img loading="lazy" className="img-fluid" src={item.image} alt="Team Image" />
                  {item.socials && (
                    <div className="social-profile d-flex align-items-end justify-content-center">
                      <ul className="list-unstyled list-inline mb-4 pb-2">
                        {item.socials.twitter && (
                          <li className="list-inline-item">
                            <a href={item.socials.twitter}>
                              <i className="lab la-twitter" />
                            </a>
                          </li>
                        )}
                        {item.socials.youtube && (
                          <li className="list-inline-item">
                            <a href={item.socials.youtube}>
                              <i className="lab la-youtube" />
                            </a>
                          </li>
                        )}
                        {item.socials.instagram && (
                          <li className="list-inline-item">
                            <a href={item.socials.instagram}>
                              <i className="lab la-instagram" />
                            </a>
                          </li>
                        )}
                        {item.socials.linkedin && (
                          <li className="list-inline-item">
                            <a href={item.socials.linkedin}>
                              <i className="lab la-linkedin-in" />
                            </a>
                          </li>
                        )}
                        {item.socials.facebook && (
                          <li className="list-inline-item">
                            <a href={item.socials.facebook}>
                              <i className="lab la-facebook-f" />
                            </a>
                          </li>
                        )}
                        {item.socials.github && (
                          <li className="list-inline-item">
                            <a href={item.socials.github}>
                              <i className="lab la-github" />
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  )}
                </div>
                <div className="mt-4 text-center">
                  <h4 className="h5 text-dark">
                    {item.title}
                  </h4>
                  <p className="h6 font-weight-400 mt-1">
                    {item.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}