import React from 'react';
import { HeadingLabels } from '../components/HeadingLabels';

export const CharactersSection = () => {
  return (
    <section className="my-5 portfolio-floating overflow-hidden">
      <div className="floating-info-block-tile text-dark"></div>
      <HeadingLabels
        secondary="Visual artwork sneak peaks"
        primary="A few core Characters" />

      <div className="container portfolio-masonry mt-5">
        <div className="row gap-lg mesonaryInit">
          <div className="portfolio-item col-md-6"> {/* has-anim */}
            <div className="card portfolio-card card-hover-zoom floating-info-block" data-title="Character" data-category="Imagine action OG">
              <div className="card-img overflow-hidden">
                <img className="img-fluid" src="assets/images/characters/female.jpeg" alt="Portfolio Thumbnail" />
              </div>
            </div>
          </div>
          <div className="portfolio-item col-md-6 "> {/* has-anim */}
            <div className="card portfolio-card card-hover-zoom floating-info-block" data-title="Character" data-category="Big mama">
              <div className="card-img overflow-hidden">
                <img className="img-fluid" src="assets/images/characters/male.jpeg" alt="Portfolio Thumbnail" />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row mt-4">
              <div className="col-12 text-center has-anim">
                <a className="btn btn-circle-black mx-auto" href="portfolio.html" title="View More">+</a>
              </div>
            </div> */}
      </div>
    </section>
  )
}