import * as React from "react"
import { Layout } from "../components/Layout"
import { TeamSection } from "../sections/TeamSection"
import { EthosSection } from "../sections/EthosSection"
import { LandingHeadingSection } from "../sections/LandingHeadingSection"
import { CharactersSection } from "../sections/CharactersSection"
import { QuoteSection } from "../sections/QuoteSection"
import { FollowUsSection } from "../sections/FollowUsSection"

const LandingPage = () => {
  return (
    <Layout>
      <main>
        {/* <HeroBanner /> */}

        <LandingHeadingSection />
        <QuoteSection />
        <FollowUsSection/>
        <EthosSection />
        <CharactersSection />
        {/* <RoadMapSection /> */}
        <TeamSection showHeading />
        {/* <ServicesSection /> */}
        {/* <BrandFunkyHeadingSection /> */}
        {/* <FAQSection showHeading /> */}

        {/* <BlogsSection /> */}

        {/* <BrandFunkyHeadingSection /> */}

        {/* <div className="mb-n5 has-anim fade">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <img loading="lazy" className="img-fluid" src="/assets/images/landing-hero-cover.jpg" alt="" />
              </div>
            </div>
          </div>
        </div> */}

        {/* example, style={{marginRight: spacing + 'em'}} when using JSX. error */}
        {/* <PortfolioCarouselSection /> */}
        {/*  */}
        {/* <WorkProgressSection /> */}
        {/* Missing jQuery */}
        {/* <TestimonialsSection/> */}
        {/* <ClientsSection /> */}
      </main>
    </Layout>
  )
}

export default LandingPage
