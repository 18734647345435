import React from 'react'

interface IHeadingLabelsProps {
  primary?: string;
  secondary?: string;
}

export const HeadingLabels: React.FC<IHeadingLabelsProps> = ({ primary, secondary }) => (
  <div className="row justify-content-center">
    <div className="col-12"> {/* has-anim fade */}
      <div className="section-title text-center mb-4">
        {secondary && (
          <p className="mb-2 text-light">
            {secondary}
          </p>
        )}
        {primary && (
          <h2 className="title text-white">
            {primary}
          </h2>
        )}
      </div>
    </div>
  </div>
)