import React from "react";

export type HeadingType = {
  title?: string;
  description?: string;
}

interface ISectionHeadingProps {
  heading: HeadingType;
}

export const SectionHeading: React.FC<ISectionHeadingProps> = ({ heading }) => {
  return (
    <div className="row justify-content-center">
      <div className="col-12 has-anim fade">
        <div className="section-title text-center mb-4">
          {heading.title && (
            <p className="mb-2">
              {heading.title}
            </p>
          )}
          {heading.description && (
            <h2 className="title text-dark">
              {heading.description}
            </h2>
          )}
        </div>
      </div>
    </div>
  )
}