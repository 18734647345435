import React from 'react';
import { IADiscord, IAInstagram, IATwitter } from '../data/Socials';

export const FollowUsSection = () => {
  return (
    <div className="row justify-content-center mb-5">
      <div className="col-lg-10">
        <div className="text-center mt-5"> {/* has-anim fade */}
          <a className="btn btn-white my-3" href={IATwitter} data-text="Follow us" target="_blank">
            <span>
              <i className="lab la-twitter mr-2" />
              0xImagineAction
            </span>
          </a>
          <small className="d-block text-muted text-light my-2">
            Follow us on
          </small>
          <a href={IATwitter} data-motion-id="a 27">
            <i className="lab la-twitter mr-2 social-icons" />
          </a>
          <a href={IAInstagram} data-motion-id="a 27">
            <i className="lab la-instagram mr-2 social-icons" />
          </a>
          <a href={IADiscord} data-motion-id="a 27">
            <i className="lab la-discord social-icons" />
          </a>
        </div>
      </div>
    </div>
  )
}