import React from 'react'

export const EthosSection = () => (
  <section className="section bg-white">
    <div className="container py-5">
      <div className="row justify-content-center">
        <div className="col-md-3"> {/* has-anim fade */}
          <h2 className="text-dark font-weight-500">
            What we’re <br />
            all about
          </h2>
        </div>
        <div className="content col-md-6 offset-md-1 mt-4 mt-md-0"> {/* has-anim fade anim-delay-1 */}
          <p className="lead">
            We’re starting a movement from the streets,
            <span className="text-dark">
            {" "} by the streets, for the streets. {" "}
            </span>
            Founded by world-renowned street artists, we’re launching a brand with a community-owned treasury to fuel:
          </p>
          <ul>
            <li>
              Street performance showcase events
            </li>
            <li>
              Sustainable fashion brand partnerships
            </li>
            <li>
              Launchpads for small biz & social enterprises
            </li>
            <li>
              Street artist grants
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div className="container py-5">
      <div className="row justify-content-center row-reverse-sm">
        <div className="content col-md-6 mt-4 mt-md-0"> {/* has-anim fade anim-delay-1 */}
          <p className="lead">
            Art is at our core. As part of the Imagine Action brand, we will:
          </p>
          <ul>
            <li>
              Capture street life characters in a 10k multimedia “PFP” (profile pic) NFT collection.
            </li>
            <li>
              Eternalise ephemeral street busks and murals on the blockchain.
            </li>
            <li>
              Create personalised artwork made bespoke for community members by our founding team
            </li>
          </ul>
        </div>
        <div className="col-md-3 offset-md-1"> {/* has-anim fade */}
          <h2 className="text-dark font-weight-500">
            Our artwork
          </h2>
        </div>
      </div>
    </div>
  </section>
)