type TeamMember = {
  title: string;
  description: string;
  image: string;
  socials?: {
    twitter?: string;
    instagram?: string;
    youtube?: string;
    linkedin?: string;
    github?: string;
    facebook?: string;
  }
}

export const Teams: TeamMember[] = [{
  title: 'Moses Concas',
  description: 'Music & Brand Founder',
  image: 'assets/images/team/moses.jpeg',
  socials: {
    twitter: 'https://twitter.com/MosesConcas',
    instagram: 'https://www.instagram.com/mosesconcas/',
    youtube: 'https://www.youtube.com/channel/UCpc3yWIRxbE8QY5cgMPihpQ',
    facebook: 'https://www.facebook.com/moses.concas'
  }
}, {
  title: 'Dodici',
  description: 'Illustrator',
  image: 'assets/images/team/dodici.png',
  socials: {
    instagram: 'https://www.instagram.com/dodici.art',
    facebook: 'https://www.facebook.com/dodiciart'
  }
}, {
  title: 'iamlxgrc.sol',
  description: 'Business & Operation',
  image: 'assets/images/team/iamlxgrc.png',
  socials: {
    twitter: 'https://twitter.com/iamlxgrc'
  }
}, {
  title: 'neutronz.eth',
  description: 'Marketing',
  image: 'assets/images/team/neutronz.png',
  socials: {
    twitter: 'https://twitter.com/Rebelcatmedia'
  }
}, {
  title: 'nonamehippie.eth',
  description: 'Developer',
  image: 'assets/images/team/noname.png',
  socials: {
    twitter: 'https://twitter.com/hippie_noname'
  }
}]